import React from "react"

export default function Staff({ staff }) {
  console.log({ staff })
  return (
    <div className="text-center">
      <div className="bg-primary mx-auto rounded-full overflow-hidden h-48 w-48">
        {staff.image && (
          <img
            className="object-cover h-full w-full"
            src={`https://images.takeshape.io/${staff.image.path}`}
            alt=""
          />
        )}
      </div>
      <h2 className="text-5xl text-primary mt-5 font-serif">{staff.name}</h2>
      <p>{staff.position}</p>
      <p>{staff.licNumber}</p>
    </div>
  )
}
