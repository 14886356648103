import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroPages from "../components/heroPages"
import Image from "../components/imagesComponent/staff"
import StaffCmp from "../components/staff"
import { graphql } from "gatsby"

export default function Staff({ data }) {
  const { barText } = data.takeshape.getTopBar
  const footer = data.takeshape.getContactUsPage
  const { title, subtitle, list } = data.takeshape.getStaffPage
  return (
    <Layout footer={footer} barText={barText}>
      <SEO title="Staff" />
      <HeroPages title={title} text={subtitle} image={<Image />} />
      <div className="container mx-auto px-5 grid md:grid-cols-2 gap-10 md:mt-16 md:mb-24 mb-10">
        {list.map((staff, index) => (
          <StaffCmp key={index} staff={staff} />
        ))}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($locale: String) {
    takeshape {
      getStaffPage(locale: $locale) {
        title
        list {
          name
          image {
            path
          }
          licNumber
          position
        }
      }
      getContactUsPage {
        phones {
          phone
        }
        facebook
        instagram
      }
      getTopBar(locale: $locale) {
        barText
      }
    }
  }
`
